import "./App.css";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";

import Routes from "./routes/index";
const history = createBrowserHistory();

function App() {
    return (
        <Router history={history}>
            <Routes />
        </Router>
    );
}

export default App;
